import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import CustomFieldModal from "./CustomFieldModal";
import { toast } from "react-hot-toast";
import { AuthContext } from "../AuthContext";
import { RxCross2 } from "react-icons/rx";
import { IoDuplicate } from "react-icons/io5";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import LineItemModal from "./LineItemModal";

function VisualizedData({ responseData, previewModalContent }) {
  const { fetchData } = useContext(AuthContext);
  const [editableData, setEditableData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);
  const [isConfirmFieldDeleteModalOpen, setIsConfirmFieldDeleteModalOpen] =
    useState(false);
  const [deleteRowIndex, setDeleteRowIndex] = useState(null);
  const [deleteFieldIndex, setDeleteFieldIndex] = useState(null);
  const [data, setData] = useState(
    responseData?.line_items || responseData?.transactions || []
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [selectedColumns, setSelectedColumns] = useState([]);
  const [isAllColumnsSelected, setIsAllColumnsSelected] = useState(false);

  useEffect(() => {
    if (responseData?.transactions) {
      setSelectedColumns([
        "transaction_date",
        "reference_number",
        "description",
        "debit",
        "credit",
        "balance",
      ]);
    } else if (responseData?.line_items) {
      setSelectedColumns([
        "item_name",
        "item_description",
        "item_quantity",
        "item_total_amount",
      ]);
    }
  }, [responseData]);

  const lineItemColumns = [
    { value: "item_name", label: "Item Name" },
    { value: "item_description", label: "Description" },
    { value: "item_hsn_or_sac_code", label: "Item HSN/SAC code" },
    { value: "item_unit_of_measurement", label: "Unit of Measurement" },
    { value: "item_serial_number", label: "Serial Number" },
    { value: "item_quantity", label: "Item Quantity" },
    { value: "alter_quantity", label: "Alter Quantity" },
    { value: "item_rate", label: "Item Rate" },
    { value: "item_total_amount", label: "Item Value" },
    { value: "item_discount_percentage", label: "Discount product wise (%)" },
    { value: "item_discount_amount", label: "Discount product wise (Amount)" },
    { value: "additional_item_cost", label: "Additional Cost Product wise" },
    { value: "item_sgst_rate_percentage", label: "SGST Rate" },
    { value: "item_sgst_amount", label: "SGST Amount" },
    { value: "item_cgst_rate_percentage", label: "CGST Rate" },
    { value: "item_cgst_amount", label: "CGST Amount" },
    { value: "item_igst_rate_percentage", label: "IGST Rate" },
    { value: "item_igst_amount", label: "IGST Amount" },
    { value: "item_quantity_cess_rate_percentage", label: "QTY cess Rate" },
    { value: "item_quantity_cess_amount", label: "QTY cess Amount" },
    { value: "item_cess_rate_percentage", label: "Cess Rate" },
    { value: "item_cess_amount", label: "Cess Amount" },
  ];

  const transactionColumns = [
    { value: "transaction_date", label: "Transaction Date" },
    { value: "reference_number", label: "Reference Number" },
    { value: "description", label: "Description" },
    { value: "debit", label: "Debit" },
    { value: "credit", label: "Credit" },
    { value: "balance", label: "Balance" },
  ];

  useEffect(() => {
    setSelectedColumns(selectedColumns);
    setData(editableData?.line_items || editableData?.transactions || []);
  }, [editableData, selectedColumns]);

  const handleColumnChange = (selectedOptions) => {
    const allValues = (
      editableData.line_items ? lineItemColumns : transactionColumns
    ).map((col) => col.value);

    if (selectedOptions.some((option) => option.value === "all_columns")) {
      setIsAllColumnsSelected(true);
      setSelectedColumns(allValues);
    } else {
      setIsAllColumnsSelected(false);
      setSelectedColumns(selectedOptions.map((option) => option.value));
    }
  };

  const dropdownOptions = [
    { value: "all_columns", label: "All Columns" },
    ...(editableData.line_items ? lineItemColumns : transactionColumns),
  ];

  const textStyle = {
    border: "none",
    backgroundColor: "transparent",
    outline: "none",
    padding: "0",
    fontSize: "14px",
  };

  const columns = (
    editableData.line_items ? lineItemColumns : transactionColumns
  )
    .filter((column) => selectedColumns.includes(column.value))
    .map((column) => ({
      name: <span title={column.label}>{column.label}</span>,
      selector: (row) => row[column.value],
      editable: true,
      cell: (row, index) => (
        <input
          type="text"
          style={textStyle}
          value={row[column.value] || ""}
          onChange={(e) =>
            handleRowDataChange(index, column.value, e.target.value)
          }
        />
      ),
    }));

  const handleRowDataChange = (rowIndex, field, value) => {
    setEditableData((prevData) => {
      const key = prevData.line_items ? "line_items" : "transactions";
      const items = prevData[key] || [];
      const actualIndex = (currentPage - 1) * rowsPerPage + rowIndex;
      const updatedItems = [...items];
      if (updatedItems[actualIndex]) {
        updatedItems[actualIndex] = {
          ...updatedItems[actualIndex],
          [field]: value,
        };
      }

      return { ...prevData, [key]: updatedItems };
    });
  };

  // Actions Column
  columns.push({
    name: "Actions",
    cell: (row, index) => (
      <div className="flex gap-2">
        <button
          className="text-blue-500 hover:text-blue-700"
          onClick={() => handleDuplicateRow(index)}
        >
          <IoDuplicate title="Duplicate Row" />
        </button>
        <button
          className="text-red-500 hover:text-red-700"
          onClick={() => handleDeleteRow(index)}
        >
          <RxCross2 title="Delete Row" size={15} />
        </button>
      </div>
    ),
  });

  const handleDuplicateRow = (rowIndex) => {
    setEditableData((prevData) => {
      const key = prevData.line_items ? "line_items" : "transactions";
      const items = prevData[key] || [];
      const actualIndex = (currentPage - 1) * rowsPerPage + rowIndex;
      const duplicatedRow = { ...items[actualIndex] };
      const updatedItems = [...items];
      updatedItems.splice(actualIndex + 1, 0, duplicatedRow);

      return { ...prevData, [key]: updatedItems };
    });
  };

  const handleDeleteField = (keyToDelete) => {
    setDeleteFieldIndex(keyToDelete);
    setIsConfirmFieldDeleteModalOpen(true);
  };

  const handleConfirmDeleteField = () => {
    setEditableData((prevData) => {
      const newData = { ...prevData };
      delete newData[deleteFieldIndex];
      return newData;
    });
    setIsConfirmFieldDeleteModalOpen(false);
  };

  const handleDeleteRow = (rowIndex) => {
    const actualIndex = (currentPage - 1) * rowsPerPage + rowIndex;
    setDeleteRowIndex(actualIndex);
    setIsConfirmDeleteModalOpen(true);
  };

  const handleConfirmDeleteRow = () => {
    setEditableData((prevData) => {
      const key = prevData.line_items ? "line_items" : "transactions";
      const items = prevData[key] || [];
      const updatedItems = [...items];
      updatedItems.splice(deleteRowIndex, 1);

      return { ...prevData, [key]: updatedItems };
    });

    setIsConfirmDeleteModalOpen(false);
  };

  useEffect(() => {
    if (responseData && Object.keys(responseData).length > 0) {
      setEditableData(responseData);
    } else {
      setEditableData({});
    }
  }, [responseData, previewModalContent]);

  const handleAddCustomField = (field) => {
    let updatedData;

    updatedData = {
      ...editableData,
      [field.key]: field.value,
    };

    setEditableData(updatedData);
    handleSaveData(updatedData);
  };

  const handleKeyChange = (oldKey, newKey) => {
    if (oldKey === newKey) return;
    if (Object.keys(editableData).includes(newKey)) {
      toast.error("Key already exists.");
      return;
    }
    const entries = Object.entries(editableData);
    const index = entries.findIndex(([key, _]) => key === oldKey);
    if (index === -1) return;
    entries[index][0] = newKey;
    const newData = Object.fromEntries(entries);
    setEditableData(newData);
  };

  const handleDataChange = (key, value) => {
    setEditableData((prevData) => ({ ...prevData, [key]: value }));
  };

  const handleSaveData = async (data = editableData) => {
    setIsSubmitting(true);
    try {
      const token = JSON.parse(localStorage.getItem("auth"))?.access;
      if (!token) {
        toast.error("Access token not found. Please log in.");
        setIsSubmitting(false);
        return;
      }

      if (!previewModalContent?.id) {
        toast.error("File ID is missing.");
        setIsSubmitting(false);
        return;
      }

      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/uploads/files/${previewModalContent.id}/update/`,
        {
          information: data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Data updated successfully");
        const bulkReviewContent =
          JSON.parse(localStorage.getItem("bulkReviewContent")) || [];
        const updatedBulkReviewContent = bulkReviewContent.map((file) => {
          if (file.id === previewModalContent.id) {
            return {
              ...file,
              information: response.data.information,
            };
          }
          return file;
        });
        localStorage.setItem(
          "bulkReviewContent",
          JSON.stringify(updatedBulkReviewContent)
        );
        fetchData();
        setEditableData(response.data.information);
      } else {
        toast.error("Failed to update data.");
      }
    } catch (error) {
      toast.error("Error updating data. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const [isLineItemModalOpen, setIsLineItemModalOpen] = useState(false);

  // Utility functions for default data
  const getDefaultLineItem = () => ({
    item_name: "",
    item_description: "",
    item_hsn_or_sac_code: null,
    item_unit_of_measurement: null,
    item_serial_number: null,
    item_quantity: null,
    alter_quantity: null,
    item_rate: null,
    item_total_amount: null,
    item_discount_percentage: null,
    item_discount_amount: null,
    additional_item_cost: null,
    item_sgst_rate_percentage: null,
    item_sgst_amount: null,
    item_cgst_rate_percentage: null,
    item_cgst_amount: null,
    item_igst_rate_percentage: null,
    item_igst_amount: null,
    item_quantity_cess_rate_percentage: null,
    item_quantity_cess_amount: null,
    item_cess_rate_percentage: null,
    item_cess_amount: null,
  });

  const getDefaultTransaction = () => ({
    transaction_date: "",
    reference_number: "",
    description: "",
    debit: 0,
    credit: 0,
    balance: 0,
  });

  const [newRowData, setNewRowData] = useState(
    responseData?.line_items ? getDefaultLineItem() : getDefaultTransaction()
  );

  const handleAddRow = () => {
    setIsLineItemModalOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRowData((prev) => ({
      ...prev,
      // [name]: isNaN(value) || value.trim() === "" ? value : parseFloat(value),
      [name]: value,
    }));
  };

  const handleAddToTable = () => {
    setEditableData((prevData) => {
      const key = prevData.line_items ? "line_items" : "transactions";
      const updatedItems = [...(prevData[key] || []), newRowData];
      return { ...prevData, [key]: updatedItems };
    });
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setIsLineItemModalOpen(false);
    resetFormData();
  };

  const resetFormData = () => {
    setNewRowData(
      responseData?.line_items ? getDefaultLineItem() : getDefaultTransaction()
    );
  };

  const formatKey = (key) => {
    return key
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const formatName = (name) => {
    if (!name) return "";

    return name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const renderDataFields = () => {
    const { line_items, transactions, ...otherFields } = editableData || {};

    // Predefined list of field names for sequential mapping
    const fieldOrder = [
      "invoice_number",
      "invoice_amount",
      "invoice_date",
      "invoice_currency",
      "seller_name",
      "seller_address",
      "seller_city",
      "seller_state",
      "seller_pan",
      "seller_gst",
      "buyer_name",
      "buyer_address",
      "buyer_city",
      "buyer_state",
      "buyer_pan",
      "buyer_gst",
      "ship_to_pan",
      "ship_to_gst",
      "lut_bond_number",
      "lut_bond_date",
      "tcs_rate_percentage",
      "tcs_amount",
      "additional_post_tax_items",
      "additional_post_tax_items_amount",
      "vehicle_number",
      "lr_number",
      "credit_days",
      "e_way_bill_number",
      "e_invoice_number",
      "purchase_order_number",
      "delivery_number",
      "transporter_name",
      "batch_number",
      "serial_number",
    ];

    // Mapping of field keys to descriptive labels
    const fieldLabels = {
      invoice_number: "Invoice Number",
      invoice_amount: "Invoice Amount",
      invoice_date: "Invoice Date",
      invoice_currency: "Invoice Currency",
      seller_name: "Seller Name",
      seller_address: "Seller Address",
      seller_city: "Seller City",
      seller_state: "Seller State",
      seller_pan: "Seller PAN",
      seller_gst: "Seller GST",
      buyer_name: "Buyer Name",
      buyer_address: "Buyer Address",
      buyer_city: "Buyer City",
      buyer_state: "Buyer State",
      buyer_pan: "Buyer PAN",
      buyer_gst: "Buyer GST",
      ship_to_pan: "Ship to PAN",
      ship_to_gst: "Ship to GST",
      lut_bond_number: "LUT Bond Number",
      lut_bond_date: "LUT Bond Date",
      tcs_rate_percentage: "TCS Rate Percentage",
      tcs_amount: "TCS Amount",
      additional_post_tax_items: "Additional Post-Tax Items",
      additional_post_tax_items_amount: "Additional Post-Tax Items Amount",
      vehicle_number: "Vehicle Number",
      lr_number: "LR Number",
      credit_days: "Credit Days",
      e_way_bill_number: "E-Way Bill Number",
      e_invoice_number: "E-Invoice Number",
      purchase_order_number: "Purchase Order Number",
      delivery_number: "Delivery Number",
      transporter_name: "Transporter Name",
      batch_number: "Batch Number",
      serial_number: "Serial Number",
    };

    // Get fields not in fieldOrder
    const additionalFields = Object.keys(otherFields).filter(
      (field) => !fieldOrder.includes(field)
    );

    // Combine ordered fields with additional fields
    const allFields = [...fieldOrder, ...additionalFields];

    return (
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {allFields.map((field) =>
          field in otherFields ? (
            <div
              className="mb-2 flex flex-col gap-2 items-center roboto-regular"
              key={field}
            >
              <div className="flex justify-between w-full gap-2">
                {/* Label */}
                <label
                  htmlFor={field}
                  className="text-gray-600 font-medium w-full"
                >
                  {fieldLabels[field] || formatKey(field)}
                </label>
                <button
                  onClick={() => handleDeleteField(field)}
                  className="text-red-500 hover:text-red-700 focus:outline-none"
                  title="Delete Field"
                >
                  <RxCross2 size={20} />
                </button>
              </div>
              {/* Editable Value */}
              <input
                id={field}
                type="text"
                className="w-full border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none"
                value={otherFields[field] || ""}
                onChange={(e) => handleDataChange(field, e.target.value)}
                title={formatKey(field)}
              />
            </div>
          ) : null
        )}
      </div>
    );
  };

  return (
    <div className=" max-w-[56vw]  bg-red- max-h-[86vh] overflow-auto">
      {/* Heading and Action Buttons */}
      <div className="flex justify-between items-center mb-0 bg-white rounded-t-md p-1 py-2 sticky top-0">
        <h2 className=" text-slate-800 raleway-regular ml-2">
          {formatName(previewModalContent?.file_type)}
        </h2>
        {editableData &&
          typeof editableData === "object" &&
          Object.keys(editableData).length > 0 && (
            <div className="flex gap-2">
              <button
                type="button"
                onClick={() => setIsModalOpen(true)}
                className="bg-white text-sky-500 px-3 py-1 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out raleway-regular"
              >
                Add Custom Field
              </button>

              <button
                type="button"
                onClick={() => handleSaveData()}
                className={`bg-white text-sky-500 px-3 py-1 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out raleway-regular mr-2 ${
                  isSubmitting ? "cursor-not-allowed opacity-50" : ""
                }`}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Saving..." : "Save Changes"}
              </button>
            </div>
          )}
      </div>

      <div
        className="rounded-b-lg p-6 bg-white max-h-[80%] shadow-lg mb-2"
        style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 5px 15px" }}
      >
        {editableData && (
          <>
            {renderDataFields()}

            {/* Custom Field Modal */}
            <CustomFieldModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              onSave={handleAddCustomField}
            />
          </>
        )}
      </div>
      {/* Data Table */}

      <div className="flex justify-end items-center">
        <button
          type="button"
          onClick={handleAddRow}
          className="bg-white text-sky-500 px-3 mt-2 py-1 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out raleway-regular mx-2"
        >
          Add line item
        </button>
      </div>
      {data?.length > 0 && (
        <Row className="mb-3">
          <Col md="12">
            <Form.Label>Select Columns to Display</Form.Label>
            <Select
              options={dropdownOptions}
              isMulti
              value={
                isAllColumnsSelected
                  ? [{ value: "all_columns", label: "All Columns" }]
                  : dropdownOptions.filter((option) =>
                      selectedColumns.includes(option.value)
                    )
              }
              onChange={handleColumnChange}
            />
          </Col>
        </Row>
      )}
      {data?.length > 0 && (
        <div className="mt-0">
          {data?.length > 0 && (
            <div className="data-table-container overflow-auto w-full">
              <DataTable
                columns={columns}
                data={data.slice(
                  (currentPage - 1) * rowsPerPage,
                  currentPage * rowsPerPage
                )}
                pagination
                paginationServer
                paginationTotalRows={data.length}
                onChangePage={(page) => setCurrentPage(page)}
                onChangeRowsPerPage={(newPerPage) => {
                  setRowsPerPage(newPerPage);
                  setCurrentPage(1);
                }}
                highlightOnHover
                className="lato-regular"
                pointerOnHover
                customStyles={{
                  headCells: {
                    style: {
                      color: "black",
                      backgroundColor: "white",
                      borderBottom: "2px solid #e2e8f0",
                    },
                  },
                  cells: {
                    style: {
                      color: "#2d3748",
                      fontSize: "14px",
                      padding: "10px 12px",
                      border: "0.1px solid #cad4e3",
                      borderRadius: "6px",
                      margin: "2px",
                    },
                  },
                  rows: {
                    style: {
                      backgroundColor: "white",
                      "&:hover": {
                        backgroundColor: "#f7fafc",
                      },
                      padding: "5px 2px",
                    },
                  },
                }}
              />
            </div>
          )}
        </div>
      )}
      <ConfirmDeleteModal
        isOpen={isConfirmDeleteModalOpen}
        onClose={() => setIsConfirmDeleteModalOpen(false)}
        onConfirm={handleConfirmDeleteRow}
      />
      {isConfirmFieldDeleteModalOpen && (
        <ConfirmDeleteModal
          isOpen={isConfirmFieldDeleteModalOpen}
          onClose={() => setIsConfirmFieldDeleteModalOpen(false)}
          onConfirm={handleConfirmDeleteField}
        />
      )}
      {isLineItemModalOpen && (
        <LineItemModal
          isOpen={isLineItemModalOpen}
          onClose={handleCloseModal}
          onSubmit={handleAddToTable}
          formData={newRowData}
          onInputChange={handleInputChange}
        />
      )}
    </div>
  );
}

export default VisualizedData;
