import React from "react";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";

Modal.setAppElement("#root"); // Accessibility requirement

const LineItemModal = ({
  isOpen,
  onClose,
  onSubmit,
  formData,
  onInputChange,
}) => {
  // Extract keys from formData to create dynamic form fields
  const fieldKeys = Object.keys(formData);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Add Data"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: "500px",
          maxHeight: "70vh",
          overflow: "auto",
          padding: "0",
          borderRadius: "0.5rem",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.25)",
        },
      }}
      shouldCloseOnOverlayClick={false}
    >
      <div className="bg-white rounded-lg">
        <div className="flex items-center justify-between p-4 bg-slate-50 border-b sticky top-0 z-50">
          <h3 className="text-lg font-semibold text-gray-800">
            Add Line Item Details
          </h3>
          <button
            onClick={onClose}
            className="text-gray-600 hover:text-gray-800"
            aria-label="Close"
          >
            <AiOutlineClose size={20} />
          </button>
        </div>
        <form className="p-5">
          {fieldKeys.map((key, index) => (
            <div key={index} className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {key.replace("_", " ").toUpperCase()}:
              </label>

              <input
                // type={typeof formData[key] === "number" ? "number" : "text"}
                type="text"
                name={key}
                value={formData[key]}
                onChange={onInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          ))}
          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="bg-white text-red-500 py-2 px-4 border border-red-500 rounded focus:outline-none focus:ring-2 focus:ring-red-500 hover:bg-red-500 hover:text-white transition duration-300 ease-in-out"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={onSubmit}
              className="bg-white text-sky-500 px-4 py-2 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
            >
              Add
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default LineItemModal;
